<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!------------------------------------STEPPER-------------------------------------->
    <div v-if="!mobileDevice">
      <v-row class="mb-0 d-flex justify-center align-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          class="pb-0 mb-0"
        >
          <v-card class="white px-auto pb-0 mb-0">
            <v-stepper>
              <v-stepper-header>
                <v-stepper-step
                  step="1"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  HOME INFO
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  CHOOSE PLAN
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  PURCHASE PLAN
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  step="4"
                >
                  DOWNLOAD YOUR POLICY
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <!-----------------------------------------TITLE-------------------------------------->
      <v-row class="mt-6">
        <v-col class="text-center">
          <div class="title-text">
            Choose A Plan That’s Right For You
          </div>
        </v-col>
      </v-row>
      <!--------------------------------------PLAN CARDS------------------------------------>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          class="text-center"
        >
          <p>These rates are preliminary quotes to give you an idea on what your policy will cost. There are still discounts to be applied and coverage options to choose from. To continue, select the quote you prefer.</p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center pb-16">
        <v-col
          cols="12"
          sm="11"
          md="9"
          class="d-flex justify-space-between"
        >
          <!---------------------CARD1----------------------->
          <v-card
            v-model="premium"
            class="pa-4 travel--text"
            width="32%"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ smallQuotePremiumH3 }}/yr
            </div>
            <div class="plan-sub text-center font-weight-bold">
              Payment Plans Available
            </div>
            <v-divider
              class="my-2 travel"
            ></v-divider>
            <v-list-item
              three-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ smallQuoteDeductibleH3 }} Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ smallQuoteReplacementH3 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Coverage For Your Home
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="travel white--text font-weight-bold"
                  @click="setPlan(plans[0].name, smallQuoteNumber, smallQuote), clickedFieldGlobal('premium', 24, 'Homeowners Survey v1')"
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <!---------------------CARD2----------------------->
          <v-card
            v-model="preferred"
            class=" pa-4 primary--text"
            width="32%"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ mediumQuotePremiumH3 }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider
              class="my-2 primary"
            ></v-divider>
            <v-list-item
              three-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ mediumQuoteDeductibleH3 }} Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ mediumQuoteReplacementH3 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Coverage For Your Home
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="primary white--text font-weight-bold"
                  @click="setPlan(plans[1].name, mediumQuoteNumber, mediumQuote), clickedFieldGlobal('preferred', 25, 'Homeowners Survey v1')"
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <!---------------------CARD3----------------------->
          <v-card
            v-model="standard"
            class="pa-4 secondary--text"
            width="32%"
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ largeQuotePremiumH3 }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider
              class="my-2 secondary"
            ></v-divider>
            <v-list-item
              three-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  ${{ largeQuoteDeductibleH3 }} Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ largeQuoteReplacementH3 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Coverage For Your Home
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="secondary white--text font-weight-bold"
                  @click="setPlan(plans[2].name, largeQuoteNumber, largeQuote), clickedFieldGlobal('standard', 26, 'Homeowners Survey v1')"
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  var numeral = require('numeral')
  export default {
    data () {
      return {
        standard: '',
        premium: '',
        preferred: '',
        selectedPlan: '',
        plans: [
          {
            name: 'Premium',
            price: '$1,697.00/yr'
          },
          {
            name: 'Preferred',
            price: '$1,839.00/yr'
          },
          {
            name: 'Standard',
            price: '$2,100.00/yr'
          }
        ]
      }
    },
    computed: {
      smallQuoteReplacementH3 () {
        return numeral(this.smallQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      mediumQuoteReplacementH3 () {
        return numeral(this.mediumQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      largeQuoteReplacementH3 () {
        return numeral(this.largeQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      smallQuoteReplacementH5 () {
        return numeral(this.smallQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      mediumQuoteReplacementH5 () {
        return numeral(this.mediumQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      largeQuoteReplacementH5 () {
        return numeral(this.largeQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      },
      smallQuoteDeductibleH3 () {
        return numeral(this.smallQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      mediumQuoteDeductibleH3 () {
        return numeral(this.mediumQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      largeQuoteDeductibleH3 () {
        return numeral(this.largeQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      smallQuoteDeductibleH5 () {
        return numeral(this.smallQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      mediumQuoteDeductibleH5 () {
        return numeral(this.mediumQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      largeQuoteDeductibleH5 () {
        return numeral(this.largeQuoteH5.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]).format('0.00')
      },
      // smallQuotePPR () {
      //   return numeral(this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      // mediumQuotePPR () {
      //   return numeral(this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      // largeQuotePPR () {
      //   return numeral(this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      // smallQuoteLiability () {
      //   return numeral(this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      // mediumQuoteLiability () {
      //   return numeral(this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      // largeQuoteLiability () {
      //   return numeral(this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]).format('0,0.00')
      // },
      smallQuotePremiumH3 () {
        return numeral(this.smallQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      mediumQuotePremiumH3 () {
        return numeral(this.mediumQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      largeQuotePremiumH3 () {
        return numeral(this.largeQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      smallQuotePremiumH5 () {
        return numeral(this.smallQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      mediumQuotePremiumH5 () {
        return numeral(this.mediumQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      largeQuotePremiumH5 () {
        return numeral(this.largeQuoteH3.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].PaymentOption[0].TotalPremium[0]).format('0.00')
      },
      smallQuoteH3 () {
        return this.$store.getters.getSmallHomeQuoteH3
      },
      mediumQuoteH3 () {
        return this.$store.getters.getMediumHomeQuoteH3
      },
      largeQuoteH3 () {
        return this.$store.getters.getLargeHomeQuoteH3
      },
      smallQuoteH5 () {
        return this.$store.getters.getSmallHomeQuoteH5
      },
      mediumQuoteH5 () {
        return this.$store.getters.getMediumHomeQuoteH5
      },
      largeQuoteH5 () {
        return this.$store.getters.getLargeHomeQuoteH5
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },

    },
    created () {
      console.log(this.largeQuote)
      console.log(this.mediumQuote)
      console.log(this.smallQuote)
    },
    methods: {
      setPlan (plan, quoteNumber, quote) {
        this.selectedPlan = plan
        let renter = Object.assign(this.$store.getters.getRenter, { quoteNumber: quoteNumber, quote: quote })
        console.log(renter)
        // this.$store.dispatch('rentersPreBind', renter)
        this.$store.dispatch('setRentalQuote', quote)
        this.$router.push({ path: '/renters/propertyinfo' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #D8FDD7;
}
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00A1B7;
}
.plan-title {
  font-size: 33px;
}
.plan-sub {
  font-size: 18px;
}
.selected {
  background-color: #005F6C;
  color: white;
}
.white {
  color: white;
}

.center {
  justify-content: center;
}
.left {
  justify-content: start;
}
.end {
  justify-content: end;
}
</style>
